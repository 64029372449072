.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #79b311;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure the header stays above other content */
}

.header-logo-home {
  display: flex;
  align-items: center;
}

.header-logo {
  cursor: pointer;
}

.header-logo img {
  width: 60px; /* Adjusted width for smaller screens */
  height: auto;
  margin-right: 10px;
}

.header-home {
  color: white;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
}

.header-title {
  font-size: 20px; /* Adjusted font size for smaller screens */
  font-weight: bold;
  color: white;
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 0px; /* Spacing between cart and account icons */
}

.header-cart,
.header-account {
  font-size: 20px; /* Adjusted font size for smaller screens */
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.header-account {
  padding-right: 30px; /* Remove right padding for the last item */
}

.cart-count {
  color: white;
  margin-left: 5px; /* Space between cart icon and count */
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .header {
    padding: 5px;
  }

  .header-logo img {
    width: 40px; /* Further reduced width for smaller screens */
  }

  .header-home {
    font-size: 14px;
    margin-left: 5px;
    font-weight: bold;
  }

  .header-title {
    font-size: 16px; /* Further reduced font size for smaller screens */
  }

  .header-cart,
  .header-account {
    font-size: 16px; /* Further reduced font size for smaller screens */
    padding-right: 10px; /* Further reduced padding for smaller screens */
  }

  .header-icons {
    gap: 10px; /* Reduce gap for smaller screens */
  }
}
