.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5; /* Light background */
}

.login-header {
  margin-bottom: 20px;
}

.login-logo {
  width: 150px; /* Adjust the logo size */
  height: auto;
}

.login-card {
  width: 100%;
  max-width: 400px; /* Limit the width of the login form */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
  padding: 20px;
  background-color: white;
}

.login-button {
  width: 100%;
  background-color: #79b311;
  border-color: #79b311;
  color: white; /* Ensure the text color is white */
  display: flex;
  justify-content: center; /* Horizontally center the text */
  align-items: center; /* Vertically center the text */
  text-align: center; /* Ensure text is centered */
}

.login-button:hover {
  background-color: #669e10;
  border-color: #669e10;
}
