.product-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5%;
  padding-top: 5%;
}
.card-container {
  display: grid;
  grid-template-columns: repeat(
    5,
    1fr
  ); /* Adjust the number of cards per row */
  gap: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease;
}

.card img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.card button.Subscribed {
  background-color: red; /* Set the background color to red */
}

.card:hover img {
  transform: scale(1.1);
}

.card h3 {
  margin-top: 10px;
  font-size: 16px; /* Adjust the font size of the title */
}

.card button {
  margin-top: 10px;
  background-color: #79b311;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px; /* Adjust the padding of the button */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .card button:hover {
  background-color: #242e10;
} */

.variation-container {
  margin-top: 10px;
}

.variation-container label {
  margin-right: 5px;
}

.variation-container select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card .out-of-stock-label {
  color: orange; /* Set the text color to cement color (light gray) */
  font-weight: bold;
}

.card button:disabled {
  background-color: #b0b0b0; /* Set the background color to cement color */
  color: #fff;
  cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .card-container {
    grid-template-columns: repeat(
      auto-fit,
      minmax(150px, 1fr)
    ); /* Adjusted for smaller screens */
    gap: 5px;
    margin-top: 6%;
  }

  .card {
    padding: 8px;
  }

  .card h3 {
    font-size: 12px; /* Adjusted for smaller screens */
  }

  .card button {
    padding: 4px 6px; /* Adjusted for smaller screens */
  }
}
