.order-history-container {
  width: 90%;
  margin: 0 auto;
  padding-top: 100px;
  
}

h2 {
  text-align: left;
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.ant-table {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden; /* Ensure smooth table borders */
}

.ant-table-thead > tr > th {
  background-color: #79b311;
  color: white;
  font-weight: bold;
  text-align: center; /* Center align table headers */
  padding: 12px;
  font-size: 16px; /* Consistent font size */
}

.ant-table-tbody > tr > td {
  text-align: center; /* Center align table cells */
  padding: 10px;
  font-size: 14px;
}

.ant-table-expanded-row {
  background-color: #f5f5f5;
}

.ant-tag {
  font-size: 14px;
  padding: 6px 10px; /* Make the tags a bit more spacious */
}

.ant-table-expanded-row .ant-table-thead > tr > th {
  background-color: #e8f5e9; /* Lighter green for expanded rows */
}

.ant-table-tbody > tr.ant-table-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.ant-table-tbody > tr.ant-table-row:hover {
  background-color: #f1f1f1;
}

/* Media query for responsive design */
@media (max-width: 767px) {
  .order-history-container {
    width: 100%;
    padding: 10px;
    padding-top: 70px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .ant-table-thead > tr > th {
    font-size: 14px;
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .ant-table-tbody > tr > td {
    font-size: 12px;
    padding: 8px; /* Adjust padding for better spacing */
  }

  .ant-tag {
    font-size: 12px;
    padding: 4px 8px; /* Adjust tag size for mobile */
  }
}

/* Table borders and spacing */
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  border-bottom: 1px solid #ddd; /* Light border for cleaner look */
}

.ant-table-tbody > tr.ant-table-expanded-row {
  border-top: none;
}

.ant-table-expanded-row .ant-table-tbody > tr > td {
  border: none; /* Remove borders in expanded rows */
}

/* Ensure all cells are center aligned */
.ant-table td,
.ant-table th {
  text-align: center; /* Center alignment for all cells */
}
