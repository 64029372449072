.cart-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 1%;
  padding-right: 10%;
  margin-top: 5%;
}

.cart-items {
  flex: 1;
  padding: 20px;
}

.cart-items h2 {
  margin-bottom: 20px;
}

.cart-items-list {
  list-style-type: none;
  padding: 0;
}

.cart-items-list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc; /* Horizontal line between cart items */
  padding-bottom: 10px; /* Add some spacing between cart items */
}

.cart-items-list li img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 4px;
}

.cart-items-list li div {
  flex: 1;
}

.cart-items-list li div label {
  text-align: left; /* Align labels to the left */
  display: block;
  margin-bottom: 5px;
}

.cart-items-list li div input,
.cart-items-list li div textarea {
  width: 100%; /* Set input width to 100% */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkout-form {
  flex: 1;
  padding: 30px;
}

.checkout-form h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.subscription-options {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.subscription-options button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
}

.subscription-options button:hover {
  background-color: #e0e0e0;
}

.subscription-options button:focus {
  outline: none;
}

.subscription-options button.active {
  background-color: #007bff;
  color: #fff;
}

.total-amount-container {
  display: flex;
  justify-content: space-between;
}

.total-amount-value {
  font-weight: bold;
}

/* .date-time-container {
  display: flex;
  justify-content: space-between;
}

.date-time-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

.time-slot-container,
.date-picker-container {
  width: 48%;
  margin-bottom: 1rem;
}

/* .subscription-options {
  display: flex;
  justify-content: space-between;
} */

.subscription-options button {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.subscription-options button.active {
  background-color: #79b311;
  color: #fff;
  border-color: #79b311;
}

/* @media only screen and (max-width: 768px) {
  .cart-page {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    display: block;
    margin-top: 10%;
  }

  .cart-items,
  .checkout-form {
    padding: 10px;
  }
  .time-slot-container,
  .date-picker-container {
    width: 100%;
  }
} */

.date-time-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.time-slot-container,
.date-picker-container {
  flex: 1 1 calc(50% - 15px);
  min-width: 200px;
}

.ant-picker-input > input,
.ant-select-selection-search-input,
.ant-input-number-input {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 768px) {
  .cart-page {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    display: block;
    margin-top: 10%;
  }

  .cart-items,
  .checkout-form {
    padding: 10px;
  }

  .time-slot-container,
  .date-picker-container {
    width: 100%;
  }

  /* Increase font size for better readability on mobile */
  body {
    font-size: 16px;
  }

  /* Increase touch target size for better usability */
  .form-group input,
  .form-group textarea,
  .subscription-options button,
  button[type="submit"] {
    min-height: 24px;
    font-size: 16px;
  }

  .form-group input,
  .form-group textarea {
    width: 95%;
  }

  /* Adjust padding for better spacing on mobile */
  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    margin-bottom: 8px;
  }

  /* Ensure buttons are large enough to tap easily */
  .subscription-options button {
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  /* Make sure the submit button is prominent */
  button[type="submit"] {
    width: 100%;
    padding: 15px;
  }

  .time-slot-container,
  .date-picker-container {
    flex: 1 1 100%;
  }

  .ant-picker,
  .ant-select,
  .ant-input-number {
    font-size: 16px;
  }

  .ant-btn {
    padding: 8px 16px;
    font-size: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input,
  button {
    font-size: 16px;
  }
}
